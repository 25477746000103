
@import url('https://fonts.googleapis.com/css2?family=Changa&family=Comfortaa&family=Fira+Sans:ital,wght@0,300;0,400;1,100;1,200&family=Julius+Sans+One&family=Overlock+SC&family=Red+Hat+Display&family=Titillium+Web&family=Urbanist&display=swap');

body {
  margin: 0;
  font-family: 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:1.3rem;
  background: rgb(239,235,230);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
header {
  box-shadow:none;
  border: none;
  color:rgb(239,235,230);
}


