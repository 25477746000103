.projects {
  word-wrap: break-word;
}

.projects .expand-btn {
  border: 1px solid rgba(86,96,108, 0.3);
  border-radius:0;
  height:100%;
}

.projects .expand-btn:hover {
  background: rgb(211, 213, 214);
  -webkit-transition: background-color 100ms linear;
  -ms-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
  border:none;
}

.projects .hero .bg {
  height:100%;
  background: url("../../assets/photos/gold-river.jpg") no-repeat center 20% fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height:40vh;
}

.projects .card-expanded {
  background: rgb(211, 213, 214)
}

.projects .card-expanded .card-image {
  max-height:600px;
  object-fit: contain;
}

/* 
  Wow. Didn't know I could do this. Fun.
   This selects only the first child of a grid container, assuming that first child
   doesn't have a breakpoint at medium, ie it is a full 12 wide aka full page width 
*/
.projects .card-expanded .MuiGrid-container .MuiGrid-item.MuiGrid-grid-xs-12:not([class*=' MuiGrid-grid-md-'],[class^='MuiGrid-grid-md-']):first-child {
  /* padding-top:0em; */
}

.projects .card-expanded .MuiGrid-container p.MuiGrid-item {
  text-align:left;
}



.projects .close-icon {
  float:right;
}


.projects .close-text {
  border: 1px solid rgba(86,96,108, 0.3);
  border-radius:3px;
  font-weight:500;
  margin-top:2em;
  padding: 10px 15px;
  background: rgb(177, 187, 190);
}

.projects iframe {
  height: 100%;
  min-height: 500px;
  width: 100%;
}



