
.header {
  height:64px;
}

.header a {
  height:100%;
  width:64px;
}

header.MuiAppBar-root {
  box-shadow:none;
}

body {
  word-wrap: break-word;
}


.main {
  min-height:100vh;
  /* background:#56606c; */
  background: #EFEBE6;
} 

h1 {
  font-size: 4rem;
} @media (min-width:600) {
  h1 {
    font-size: 7rem;
  }
}


h2 {
  font-size: 3rem;
} @media (min-width:600) {
  h2 {
    font-size: 7rem;
  }
}

a {
  text-decoration: none!important;
}

img {
  display: block;
  width: auto;
  height: auto;
  margin: auto;
}