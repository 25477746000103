.about .hero .bg {
  height:100%;
  background: url("../../assets/photos/coast-sunset.jpg") no-repeat center 80%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  min-height:40vh;
}

.about .hero .title {
  padding: 2em;
  color: black;
}

/* Specific to the .about class */
a {
  color: rgba(48, 48, 144, 0.777); /* Override link color within .about */
  text-decoration: underline !important;
}

a:hover {
  color: rgba(70, 70, 230, 0.777); /* Override hover color within .about */
  text-decoration: underline !important;
}

a:visited {
  color: rgba(41, 41, 227, 0.777); /* Override visited link color within .about */
  text-decoration: underline !important;
}